import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { chunk } from "lodash"

import SEO from "../components/seo"
import ContactFooter from "../components/ContactForm/ContactFooter"
import ProjectBox from "../components/ProjectBox"

const PortfolioPage = () => {
  const data = useStaticQuery(graphql`
    query allPortfolioItems {
      allPrismicPortfolioItem(
        sort: { order: DESC, fields: first_publication_date }
      ) {
        edges {
          node {
            uid
          }
        }
      }
      prismicBasicInfo {
        data {
          orange
          main_color_two
        }
      }
    }
  `)

  const { edges } = data.allPrismicPortfolioItem
  const { orange, main_color_two } = data.prismicBasicInfo.data

  const chunks = chunk(edges, 2)

  const flipEvenOrOdd = chunks.length % 2 !== 0

  const sectionStyle = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    padding: "0 0 75px",
  }

  const portfolioBoxStyle = {
    display: "inline-block",
  }

  const generateBlockPadding = (evenOrOdd, i) => {
    if (i !== 0) return 75
    if (!evenOrOdd && i === 0) return 50

    return 0
  }

  return (
    <>
      <SEO title="Portfolio" />

      <section
        style={{ paddingTop: 50, paddingBottom: 25, textAlign: "center" }}
      >
        <h1>Klik voor meer informatie</h1>
      </section>

      {chunks.map((chunk, i) => {
        let evenOrOdd = i % 2 === 0
        evenOrOdd = flipEvenOrOdd ? !evenOrOdd : evenOrOdd

        return (
          <section
            key={i}
            style={{
              ...sectionStyle,
              backgroundColor: evenOrOdd ? "" : main_color_two,
              paddingTop: generateBlockPadding(evenOrOdd, i),
            }}
            className={evenOrOdd ? "" : "borderradius-div-portfolio"}
          >
            {chunk.map((n, i) => (
              <ProjectBox
                key={n.node.uid}
                projectID={n.node.uid}
                bgColor={evenOrOdd ? main_color_two : orange}
                style={portfolioBoxStyle}
              />
            ))}
          </section>
        )
      })}

      <ContactFooter />
    </>
  )
}

export default PortfolioPage
