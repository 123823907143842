import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Link from "../components/HelperComponents/TransitionLink"

import Ul from "./HelperComponents/PrismicUlHelper"

const ProjectBox = ({ bgColor, projectID, style }) => {
  const data = useStaticQuery(graphql`
    query projectBox {
      allPrismicPortfolioItem {
        edges {
          node {
            uid
            data {
              titel {
                text
              }
              highlights {
                raw
              }
              afbeelding {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
      prismicBasicInfo {
        data {
          orange
          main_color_three
        }
      }
    }
  `)

  const { main_color_three } = data.prismicBasicInfo.data

  const [node] = data.allPrismicPortfolioItem.edges.filter(
    n => n.node.uid === projectID
  )

  const { afbeelding, titel, highlights } = node.node.data

  return (
    <Link
      to={`/portfolio/${node.node.uid}`}
      extraClasses="project-box"
      linkStyle={{
        color: main_color_three,
        textDecoration: "none",
        backgroundColor: bgColor,
        padding: 20,
        borderRadius: "5px 5px 40px 5px",
        textAlign: "left",
        width: "90%",
        maxWidth: 450,
        ...style,
      }}
    >
      <Img
        fluid={afbeelding.localFile.childImageSharp.fluid}
        style={{ marginBottom: 10, borderRadius: 2 }}
      />
      <h6 style={{ marginBottom: 0 }}>{titel.text}</h6>
      <Ul
        list={highlights.raw}
        ulStyle={{ fontSize: "70%", marginBottom: 0 }}
      />
    </Link>
  )
}

export default ProjectBox
