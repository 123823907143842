import React from "react"

export default ({ list, ulStyle = {}, liStyle = {} }) => {
  if (list == null) return <div />

  return (
    <ul style={ulStyle}>
      {list.map((item, i) => (
        <li style={liStyle} key={i}>
          {item.text}
        </li>
      ))}
    </ul>
  )
}
